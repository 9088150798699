import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUserDetails, useSendInvite, useGetOrganization } from "../apiCalls/userApiCalls";
import { ArrowBackIos } from "@mui/icons-material";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

export default function UserDetails() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { userId } = useParams();

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );
  const {
    mutate: sendInviteMutate,
    isLoading: isSendInviteLoading,
    isError: isSendInviteError,
    error: sendInviteError,
  } = useSendInvite();

  const organizationId = userDetails?.data.data.organization;
  const { isLoading: isOrganizationLoading, data: organizationDetails } = useGetOrganization(
    organizationId,
    token
  );

  const userEmail = userDetails?.data.data.email;

  const handleSendInvite = () => {
    const data = {
      token: token,
      email: userEmail,
    };
    sendInviteMutate(data);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="pt-10 flex flex-col gap-2 items-center">
                <form action="">
                  <div
                    className="w-28 h-28 border border-gray-200  rounded-full"
                    style={{
                      backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </form>
                <button
                  onClick={handleSendInvite}
                  className="flex h-full mt-2 text-center px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-all bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg text-white"
                >
                  Send Invite
                </button>
              </div>

              <form className="pb-8">
                <div className="space-y-3 pt-8">
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Name
                    </label>
                    <div className="text-lg py-2 px-2 flex gap-2 border-b border-gray-500 ">
                      {userDetails.data.data.name}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Email Address
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b  border-gray-500">
                      {userDetails.data.data.email}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor=""
                    >
                      Type
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b  border-gray-500">
                      {userDetails.data.data.role}
                    </div>
                  </div>
                  {organizationDetails && (
                    <div className="space-y-1">
                      <label
                        className="text-lg font-medium text-gray-500"
                        htmlFor=""
                      >
                        Organization
                      </label>
                      <div className="py-4 px-5 flex gap-2 border-b border-gray-500">
                        {organizationDetails.data.name}
                      </div>
                    </div>
                  )}
                  {isOrganizationLoading && (
                    <div className="text-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
