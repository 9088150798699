import React, { useState } from "react";
import ForemanMetric from "../components/ForemanMetric";
import moment from "moment";
import { useGetMetrics } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

function Home() {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [type, setType] = useState("foreman");
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;

  const { isLoading, data } = useGetMetrics(token, type, startDate, endDate);

  return (
    <>
      <div className="mt-24 flex gap-2 py-4 justify-center ">
        {/* <select
          name=""
          id=""
          className="rounded-md p-1 border border-gray-300 bg-white"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option value="foreman">Foreman</option>
          <option value="sales">Sales</option>
        </select> */}
        <input
          type="date"
          name="startDate"
          id="startDate"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
          className="rounded-md p-1 w-32 border border-gray-300 bg-white"
        />
        <input
          type="date"
          name="endDate"
          id="endDate"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          className="rounded-md p-1 w-32 border border-gray-300 bg-white"
        />
      </div>
      <div className="flex flex-wrap gap-4 justify-center">
        {isLoading ? (
          <Loader />
        ) : (
          data.data.data.map((item, index) => {
            if (type === "foreman") {
              return (
                <ForemanMetric
                  key={index}
                  budget={item.totalBudget}
                  labor={item.totalLaborCost}
                  nrexpense={item.totalNRExpense}
                  img={item.imgUrl}
                  name={item.name}
                  retail={item.totalRetail}
                  hours={item.totalHours}
                />
              );
            }
            //  else if (type === "sales") {
            //   return (
            //     <Link key={index} to={`/history/${item._id}`}>
            //       <SalesMetric
            //         jobs={item.jobs}
            //         amount={item.amount}
            //         bonus={item.bonus}
            //         img={item.imgUrl}
            //         name={item.name}
            //       />
            //     </Link>
            //   );
            // }
          })
        )}
      </div>
    </>
  );
}

export default Home;
